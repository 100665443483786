import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'

export default {
  initPush (router) {
    if (Capacitor.platform !== 'web') {
      this.registerPush(router)
    }
  },

  registerPush (router) {
    PushNotifications.requestPermissions().then((permission) => {
      console.log(permission)
      if (permission.receive === 'granted') {
        console.log('granted')
        PushNotifications.register()
      } else {
        console.log('not granted')
        // No permission for push granted
      }
    }).catch((err) => console.log('Error requesting permission' + err))

    PushNotifications.addListener('registration', (token) => {
      console.log('My token: ' + JSON.stringify(token))
    })

    PushNotifications.addListener('registrationError', (error) => {
      console.log('Error: ' + JSON.stringify(error))
    })

    PushNotifications.addListener('pushNotificationReceived', async (notification) => {
      console.log('Push received: ' + JSON.stringify(notification))
    })

    PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
      const data = notification.notification.data
      // make sure to handle case switching properly
      console.log('Action performed: ' + JSON.stringify(notification.notification))
      if (data.detailsId) {
        // this is not accurate
        router.push(`/home/${data.detailsId}`)
      }
    })
  }
}
