import { ref } from 'vue'

export const applyDarkMode = () => {
  const darkModeClass = 'dark-mode'

  if (enabled.value) {
    document.body.classList.add(darkModeClass)
  } else {
    document.body.classList.remove(darkModeClass)
  }
}

function getInitialValue () {
  let value = 1
  const config = localStorage.getItem('darkMode')
  if (config !== null) {
    value = !!parseInt(config)
  }

  return value
}

export const enabled = ref(getInitialValue())
