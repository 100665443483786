import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
    ready () {
      console.log('App is being served from cache by a service worker.')
    },
    registered (registration) {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available; refreshing now...')
      if (registration.waiting) {
        // Envoyer un message au service worker en attente pour qu'il s'active
        registration.waiting.postMessage({ action: 'skipWaiting' })

        // Écouter l'événement controllerchange
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (!window.isRefreshing) {
            window.isRefreshing = true
            window.location.reload()
          }
        })
      } else {
        console.log('Aucun service worker en attente à activer.')
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
