import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import InlineSvg from 'vue-inline-svg'
import setupInterceptors from './services/setupInterceptors'
import 'toastr/build/toastr.min.css'
import Pusher from 'pusher-js'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import filters from './filters'
import tokenService from './services/token.service'
import axiosInstance from './services/api'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import './registerServiceWorker'
import VueGtag from 'vue-gtag'
import VueCookieComply from 'vue-cookie-comply'
import 'vue-cookie-comply/dist/style.css'
import VueFeather from 'vue-feather'
import VueMobileDetection from 'vue-mobile-detection'
import IntroPlugin from './introPlugin.js'
import 'intro.js/introjs.css'
import './style/default.scss'
import 'vue-search-select/dist/VueSearchSelect.css'
import firebaseCloudMessagingService from './services/firebaseCloudMessaging.service'
import toastr from 'toastr'
import platform from '../src/global/platform'

toastr.options = {
  positionClass: 'toast-top-right toast-custom'
}

setupInterceptors(store)

router.beforeEach((to, from, next) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = tokenService.getLocalAccessToken()
      if (token) {
        config.headers.Authorization = 'Bearer ' + token // for Spring Boot back-end
        // config.headers['x-access-token'] = token // for Node.js Express back-end
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  axiosInstance.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config
      if (originalConfig && err.response) {
        if (
          err.response.status === 403 &&
          err.response.data.ip_denied &&
          sessionStorage.getItem('forbidden_redirection') !== 'on'
        ) {
          sessionStorage.setItem('forbidden_redirection', 'on')
          window.location.href = router.resolve({ name: 'error_403' }).href
        }
      }

      return Promise.reject(err)
    }
  )
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (!store.state.auth.status.loggedIn) {
      next({ name: 'home' })
    } else {
      next()
    }
  } else {
    next()
  }
})

library.add(fas)

const token = tokenService.getLocalAccessToken() || ''
// find if it's not possible to call it when user has complete account creation
// if (store.state.auth.status.loggedIn) {
firebaseCloudMessagingService.initPush()
// }

// Enable pusher logging - don't  include this in production
Pusher.logToConsole = process.env.NODE_ENV === 'development'

const pusher = new Pusher(process.env.VUE_APP_PUSHER_PUBLIC_KEY, {
  cluster: 'eu',
  authEndpoint: process.env.VUE_APP_ROOT_API + '/v1/pusher/auth',
  auth: {
    headers: {
      Authorization: 'Bearer ' + token,
      'X-Requested-With': 'XMLHttpRequest'
    }
  }
})

var generalChannel = pusher.subscribe('general')
var presenceChannel = pusher.subscribe('presence-online')
var privateChannel = null
if (store.state.auth.status.loggedIn) {
  privateChannel = pusher.subscribe(
    'user-' + (store.state.auth.user ? store.state.auth.user.id : 0).toString()
  )
} else {
  privateChannel = pusher.subscribe('invited')
}

const app = createApp(App)

app.config.globalProperties.$platform = platform
app.config.globalProperties.$filters = filters
app.config.globalProperties.$pusher = pusher
app.config.globalProperties.$generalChannel = generalChannel
app.config.globalProperties.$presenceChannel = presenceChannel
app.config.globalProperties.$privateChannel = privateChannel
app.config.globalProperties.$toastr = require('toastr')
// app.config.globalProperties.$capacitor = Capacitor;
// app.provide('PushNotifications', PushNotifications);

// FloatingVue.options.themes.tooltip.delay.hide = 1200000
// FloatingVue.options.themes.menu.delay.hide = 1200000
// FloatingVue.options.themes.menu.delay.hide = 1200000
// FloatingVue.options.disposeTimeout = 12222000
FloatingVue.options.themes.dropdown.placement = 'top'
FloatingVue.options.themes.menu.delay.show = 400
FloatingVue.options.positioningDisabled = false

app.use(router)
app.use(store)
app.use(VueMobileDetection)
app.use(FloatingVue, {
  themes: {
    'post-edition-icon-tooltip': {
      $extend: 'tooltip',
      placement: 'top'
    }
  }
})
app.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ID }
})
app.use(VueCookieComply)
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.component('InlineSvg', InlineSvg)
app.component('Datepicker', Datepicker)
app.component(VueFeather.name, VueFeather)
app.mount('#app')
app.use(IntroPlugin)
