const notifications = JSON.parse(localStorage.getItem('notifications'))
const initialState = {
  read: notifications ? notifications.read : true,
  data: notifications ? notifications.data : []
}

export const notification = {
  namespaced: true,
  state: initialState,
  actions: {
    setReadStatus ({ commit }, read) {
      commit('setReadStatus', read)
    },
    setNotifications ({ commit }, data) {
      commit('setNotifications', data)
    },
    addNotification ({ commit }, notification) {
      commit('addNotification', notification)
    }
  },
  mutations: {
    setReadStatus (state, read) {
      var notifications = JSON.parse(localStorage.getItem('notifications'))
      if (!notifications) {
        localStorage.setItem(
          'notifications',
          JSON.stringify({
            read: true,
            data: []
          })
        )
        state.read = true
        state.data = []
      }
      notifications = JSON.parse(localStorage.getItem('notifications'))
      notifications.read = read
      state.read = read
      localStorage.setItem('notifications', JSON.stringify(notifications))
    },
    setNotifications (state, data) {
      var notifications = JSON.parse(localStorage.getItem('notifications'))
      if (!notifications) {
        localStorage.setItem(
          'notifications',
          JSON.stringify({
            read: true,
            data: []
          })
        )
        state.read = true
        state.data = []
      }
      notifications = JSON.parse(localStorage.getItem('notifications'))
      notifications.data = data
      state.data = data
      localStorage.setItem('notifications', JSON.stringify(notifications))
    },
    addNotification (state, notification) {
      var notifications = JSON.parse(localStorage.getItem('notifications'))
      if (!notifications) {
        localStorage.setItem(
          'notifications',
          JSON.stringify({
            read: true,
            data: []
          })
        )
        state.read = true
        state.data = []
      }
      notifications = JSON.parse(localStorage.getItem('notifications'))
      const index = notifications.data.findIndex(
        (notif) => notif.id === notification.id
      )
      if (index > -1) {
        notifications.data.splice(index, 1, notifications.data[index])
      } else {
        notification.data = [notification].concat(notification.data)
      }
      state.data = notification.data
      localStorage.setItem('notifications', JSON.stringify(notifications))
    }
  }
}
