import api from './api'

class GroupService {
  // getAllSuggestions (options = {}, cancelToken = null) {
  //   return api.get('v1/group/suggestions', {
  //     params: options,
  //     cancelToken: cancelToken
  //   })
  // }
  getAllSuggestions (options = {}, cancelToken = null) {
    return api.get('v1/group/list/little', {
      params: options,
      cancelToken: cancelToken
    })
  }

  joinNext (options, cancelToken = null) {
    return api.post('v1/group/join/next', options, { cancelToken: cancelToken })
  }

  getAllOthers (options = {}, cancelToken = null) {
    return api.get('v1/group/others', {
      params: options,
      cancelToken: cancelToken
    })
  }

  getAll (options = {}, cancelToken = null) {
    return api.get('v1/group/list', {
      params: options,
      cancelToken: cancelToken
    })
  }

  getAllInvitations (options = {}, cancelToken = null) {
    return api.get('v1/group/invitations', {
      params: options,
      cancelToken: cancelToken
    })
  }

  join (options = {}, cancelToken = null) {
    return api.get('v1/group/join', {
      params: options,
      cancelToken: cancelToken
    })
  }

  leave (options = {}, cancelToken = null) {
    return api.get('v1/group/leave', {
      params: options,
      cancelToken: cancelToken
    })
  }

  profile (options = {}, cancelToken = null) {
    return api.get('v1/group/profile', {
      params: options,
      cancelToken: cancelToken
    })
  }

  addVisit (options = {}, cancelToken = null) {
    return api.get('v1/group/hasVisit', {
      params: options,
      cancelToken: cancelToken
    })
  }

  info (options = {}, cancelToken = null) {
    return api.get('v1/group/info', {
      params: options,
      cancelToken: cancelToken
    })
  }

  members (options = {}, cancelToken = null) {
    return api.get('v1/group/members', {
      params: options,
      cancelToken: cancelToken
    })
  }

  infoStats (options = {}, cancelToken = null) {
    return api.get('v1/group/info/stats', {
      params: options,
      cancelToken: cancelToken
    })
  }

  invite (options = {}, cancelToken = null) {
    return api.get('v1/group/invite', {
      params: options,
      cancelToken: cancelToken
    })
  }

  membersSuggestions (options = {}, cancelToken = null) {
    return api.get('v1/group/suggest_member', {
      params: options,
      cancelToken: cancelToken
    })
  }

  adminMembers (options = {}, cancelToken = null) {
    return api.get('v1/group/admin_member', {
      params: options,
      cancelToken: cancelToken
    })
  }

  moderatorMembers (options = {}, cancelToken = null) {
    return api.get('v1/group/moderator_member', {
      params: options,
      cancelToken: cancelToken
    })
  }

  adminAllForm (options = {}, cancelToken = null) {
    return api.get('v1/group/admin/all', {
      params: options,
      cancelToken: cancelToken
    })
  }

  moderatorAllForm (options = {}, cancelToken = null) {
    return api.get('v1/group/moderator/all', {
      params: options,
      cancelToken: cancelToken
    })
  }

  searchNotAdmin (options = {}, cancelToken = null) {
    return api.get('v1/search/not-admin', {
      params: options,
      cancelToken: cancelToken
    })
  }

  addAdmin (options = {}, cancelToken = null) {
    return api.post('v1/group/add/admins', options, {
      cancelToken: cancelToken
    })
  }

  addModerator (options = {}, cancelToken = null) {
    return api.post('v1/group/add/moderators', options, {
      cancelToken: cancelToken
    })
  }

  create (options = {}, cancelToken = null) {
    return api.post('v1/group/new', options, { cancelToken: cancelToken })
  }

  edit (options = {}, params = {}, cancelToken = null) {
    return api.post('v1/group/edit', options, {
      params: params,
      cancelToken: cancelToken
    })
  }

  getMyGroups (options = {}, cancelToken = null) {
    return api.get('v1/group/admin_groups', {
      params: options,
      cancelToken: cancelToken
    })
  }
}

export default new GroupService()
