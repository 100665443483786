import badgeService from '../services/badge.service'

const portraits = JSON.parse(localStorage.getItem('portraits'))
const initialState = {
  portraits: portraits || []
}

export const portrait = {
  namespaced: true,
  state: initialState,
  actions: {
    setPortraits ({ commit }, portraits) {
      commit('setPortraits', portraits)
    },
    getPortraits: ({ commit }) => {
      return badgeService.getPortraits().then(
        (response) => {
          if (response.data.status === 200) {
            commit('setPortraits', response.data.portraits)
          }
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    }
  },
  getters: {
    getPortraitById: state => (id) => {
      return state.portraits.find(portrait => portrait.id === id)
    }
  },
  mutations: {
    setPortraits (state, portraits) {
      localStorage.setItem('portraits', JSON.stringify(portraits))
      state.portraits = portraits
    }
  }
}
