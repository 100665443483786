import api from './api'

class BadgeService {
  getAll (options = {}, cancelToken = null) {
    return api.get('v1/badges/', { params: options, cancelToken: cancelToken })
  }

  getPortraits (options = {}, cancelToken = null) {
    return api.get('v1/badges/portraits', { params: options, cancelToken: cancelToken })
  }

  getQuests (options = {}, cancelToken = null) {
    return api.get('v1/badges/quests', { params: options, cancelToken: cancelToken })
  }

  getPercentage (options = {}) {
    return api.get('v1/badges/percentage', options)
  }

  getPercentagePortrait (options = {}) {
    return api.get('v1/badges/percentage/portrait', options)
  }

  choosePortrait (options = {}, cancelToken = null) {
    return api.get('v1/badges/choose/portrait', {
      params: options,
      cancelToken: cancelToken
    })
  }

  unchoosePortrait (options = {}, cancelToken = null) {
    return api.get('v1/badges/unchoose/portrait', {
      params: options,
      cancelToken: cancelToken
    })
  }

  updateOrder (options = {}, cancelToken = null) {
    return api.post('v1/badges/update/order', options, {
      cancelToken: cancelToken
    })
  }
}

export default new BadgeService()
