import GroupService from '../services/group.service'

const groupLocal = JSON.parse(localStorage.getItem('group'))
const menus = JSON.parse(localStorage.getItem('group_menus')) || {}
const initialState = { group: groupLocal || null, menus: menus || {} }

export const group = {
  namespaced: true,
  state: initialState,
  actions: {
    setMenus ({ commit }, menus) {
      commit('menus', menus)
      return Promise.resolve(menus)
    },
    setGroup ({ commit }, group) {
      commit('group', group)
      return Promise.resolve(group)
    },
    getGroupInfos: ({ commit }, slug) => {
      return GroupService.profile({ group: slug }).then(
        (response) => {
          if (response.data.status === 200) {
            commit('group', response.data.group)
          }
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    group: function (state, group) {
      localStorage.setItem('group', JSON.stringify(group))
      state.group = group
    },
    menus: function (state, menus) {
      localStorage.setItem('group_menus', JSON.stringify(menus))
      state.menus = menus
    }
  }
}
