import { createStore } from 'vuex'
import { auth } from './auth.module'
import { message } from './message.module'
import { notification } from './notification.module'
import { group } from './group.module'
import { page } from './page.module'
import { user } from './user.module'
import { portrait } from './portrait.module'

const store = createStore({
  modules: {
    auth,
    message,
    notification,
    group,
    page,
    user,
    portrait
  }
})

export default store
