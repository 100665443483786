/* eslint-disable */
import api from './api'
import TokenService from './token.service'

class AuthService {
  login(credential) {
    return api.post('/login_check', credential).then((response) => {
      if (response.data.token) {
        TokenService.setCredential(response.data)
      }

      return response.data
    })
  }

  logout() {
    TokenService.removeCredential()
  }

  checkRegistration() {
    return api.get('v1/auth/check-registration').then((response) => {
      return response.data
    })
  }

  getNewRefreshToken() {
    if (this.refreshing) {
      return this.refreshing
    }

    return new Promise((resolve, reject) => {
      this.refreshing = api
        .post('/token/refresh', {
          refresh_token: TokenService.getLocalRefreshToken()
        })
        .then((response) => {
          this.refreshing = null
          resolve(response.data)
          return response.data
        })
        .catch((err) => {
          this.refreshing = null
          reject(err)
          throw err
        })
    })
  }

  checkHaveEndedInAfterRegistrationPage() {
    return api.get('v1/auth/check-is-ended').then((response) => {
      return response.data
    })
  }

  saveHaveEndedInAfterRegistrationPage() {
    return api.get('v1/auth/save-is-ended').then((response) => {
      return response.data
    })
  }

  register(register) {
    return api.post('v1/auth/register', register).then((response) => {
      if (response.data.token) {
        TokenService.setCredential(response.data)
      }

      return response.data
    })
  }

  resetSendEmail(options = {}, params = {}) {
    return api.post('v1/auth/resetting/send-email', options, { params: params })
  }

  resetCheckEmail(params = {}) {
    return api.get('v1/auth/resetting/check-email', { params: params })
  }

  resetCheckToken(params = {}) {
    return api.get('v1/auth/resetting/reset', { params: params })
  }

  resetPassword(options = {}) {
    return api.post('v1/auth/resetting/reset', options)
  }

  registerCheckEmail(options = {}) {
    return api.post('v1/auth/register/check-email', options)
  }

  registerCheckToken(token) {
    return api.post('v1/auth/register/confirm/' + token)
  }

  facebookConnect() {
    return api.get('v1/oauth/facebook')
  }

  facebookConnectCheck(params = {}) {
    return api.get('v1/oauth/facebook/check', { params }).then((response) => {
      return response.data
    })
  }

  facebookConnectRegister(data, params = {}) {
    return api
      .post('v1/oauth/facebook/register', data, { params })
      .then((response) => {
        return response.data
      })
  }

  googleConnect() {
    return api.get('v1/oauth/google')
  }

  googleConnectCallback(params = {}) {
    return api.get('v1/oauth/google/callback', { params }).then((response) => {
      return response.data
    })
  }

  googleConnectCheck(data, params = {}) {
    return api
      .post('v1/oauth/google/check', data, { params })
      .then((response) => {
        return response.data
      })
  }

  googleConnectRegister(data, params = {}) {
    return api
      .post('v1/oauth/google/register', data, { params })
      .then((response) => {
        return response.data
      })
  }

  youtubeConnect() {
    return api.get('v1/oauth/youtube')
  }

  youtubeConnectCallback(params = {}) {
    return api.get('v1/oauth/youtube/callback', { params }).then((response) => {
      return response.data
    })
  }

  youtubeConnectCheck(data, params = {}) {
    return api
      .post('v1/oauth/youtube/check', data, { params })
      .then((response) => {
        return response.data
      })
  }

  youtubeConnectRegister(data, params = {}) {
    return api
      .post('v1/oauth/youtube/register', data, { params })
      .then((response) => {
        return response.data
      })
  }

  twitchConnect() {
    return api.get('v1/oauth/twitch')
  }

  twitchConnectCallback(params = {}) {
    return api.get('v1/oauth/twitch/callback', { params }).then((response) => {
      return response.data
    })
  }

  twitchConnectCheck(data, params = {}) {
    return api
      .post('v1/oauth/twitch/check', data, { params })
      .then((response) => {
        return response.data
      })
  }

  twitchConnectRegister(data, params = {}) {
    return api
      .post('v1/oauth/twitch/register', data, { params })
      .then((response) => {
        return response.data
      })
  }

  twitterConnect() {
    return api.get('v1/oauth/twitter')
  }

  twitterConnectCheck(data, params = {}) {
    return api
      .post('v1/oauth/twitter/check', data, { params })
      .then((response) => {
        return response.data
      })
  }

  twitterConnectRegister(data, params = {}) {
    return api
      .post('v1/oauth/twitter/register', data, { params })
      .then((response) => {
        return response.data
      })
  }
}

export default new AuthService()
