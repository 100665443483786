import moment from 'moment'

const filters = {
  orderMessage (messages) {
    messages.sort(function (a, b) {
      return (
        (b.timestamp == null ? b.friend_timestamp : b.timestamp) -
        (a.timestamp == null ? a.friend_timestamp : a.timestamp)
      )
    })
    return messages
  },
  allreadyWritedMessages (messages) {
    const messagesFiltereds = messages.filter(function (e) {
      return e.allready_write
    })
    return messagesFiltereds
  },
  orderReactions (array) {
    var reactions = array
    reactions.sort(function (a, b) {
      return (
        a.users.length - b.users.length ||
        parseInt(b.react.id) - parseInt(a.react.id)
      )
    })
    return reactions
  },
  cut (value, wordwise, max, tail) {
    if (!value) return ''

    max = parseInt(max, 10)

    if (!max) return value

    if (value.length <= max) return value

    value = value.substr(0, max)
    if (wordwise) {
      var lastspace = value.lastIndexOf(' ')
      if (lastspace !== -1) {
        // Also remove . and , so its gives a cleaner result.
        if (
          value.charAt(lastspace - 1) === '.' ||
          value.charAt(lastspace - 1) === ','
        ) {
          lastspace = lastspace - 1
        }
        value = value.substr(0, lastspace)
      }
    }

    return value + (tail || ' …')
  },
  formatDate (date, format) {
    var myDate = new Date(date)
    if (moment(myDate).format(format) === 'Invalid date') return ''
    return moment(myDate).format(format)
  },
  year: function (date) {
    const year = moment().diff(date, 'years', false)
    if (year === 'Invalid date') return ''
    return year
  },
  range: function (input, total) {
    total = parseInt(total)
    for (var i = 1; i <= total; i++) {
      input.push(i)
    }
    return input
  },
  isYoutubeVideo: function (idVideo) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/
    const match = idVideo.match(regExp)
    return !!(match && match[2].length === 11)
  },
  EmbedYoutubeUrl: function (idVideo) {
    if (idVideo !== undefined) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/
      const match = idVideo.match(regExp)
      idVideo = match && match[2].length === 11 ? match[2] : null
    } else {
      idVideo = ''
    }
    return 'https://www.youtube.com/embed/' + idVideo
  },
  getYoutubeId: function (idVideo) {
    if (idVideo !== undefined) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/
      const match = idVideo.match(regExp)
      idVideo = match && match[2].length === 11 ? match[2] : null
    } else {
      idVideo = ''
    }
    return idVideo
  },
  limit: function (array = [], number) {
    const tmp = [...array]
    return tmp.slice(0, number - 1)
  },
  currency: function (price) {
    if (isNaN(price) || price === null) {
      return '0,00'
    }
    price = parseFloat(price)
    return price.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' }).replace('€', '').trim()
  }
}

export default filters
