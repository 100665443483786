import PageService from '../services/page.service'

const pageLocal = JSON.parse(localStorage.getItem('page'))
const menus = JSON.parse(localStorage.getItem('page_menus')) || {}
const defaultProfilePage =
  JSON.parse(localStorage.getItem('default_profile_page')) || null
const canSwitchProfilePage =
  JSON.parse(localStorage.getItem('can_switch_profile_page')) || false
const initialState = {
  page: pageLocal || null,
  menus: menus || {},
  defaultProfilePage,
  canSwitchProfilePage
}

export const page = {
  namespaced: true,
  state: initialState,
  actions: {
    setMenus ({ commit }, menus) {
      commit('menus', menus)
      return Promise.resolve(menus)
    },
    setPage ({ commit }, page) {
      commit('page', page)
      return Promise.resolve(page)
    },
    setDefaultProfilePage ({ commit }, defaultProfilePage) {
      commit('defaultProfilePage', defaultProfilePage)
      return Promise.resolve(defaultProfilePage)
    },
    setCanSwitchProfilePage ({ commit }, canSwitchProfilePage) {
      commit('canSwitchProfilePage', canSwitchProfilePage)
      return Promise.resolve(canSwitchProfilePage)
    },
    getPageInfos: ({ commit }, slug) => {
      return PageService.profile({ page: slug }).then(
        (response) => {
          if (response.data.status === 200) {
            commit('page', response.data.page)
          }
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    canSwitchPagesInfos: ({ commit }) => {
      return PageService.canSwitchPages().then(
        (response) => {
          if (response.data.status === 200) {
            commit('canSwitchProfilePage', response.data.can)
          }
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    page: function (state, page) {
      localStorage.setItem('page', JSON.stringify(page))
      state.page = page
    },
    menus: function (state, menus) {
      localStorage.setItem('page_menus', JSON.stringify(menus))
      state.menus = menus
    },
    defaultProfilePage: function (state, defaultProfilePage) {
      localStorage.setItem(
        'default_profile_page',
        JSON.stringify(defaultProfilePage)
      )
      state.defaultProfilePage = defaultProfilePage
    },
    canSwitchProfilePage: function (state, canSwitchProfilePage) {
      localStorage.setItem(
        'can_switch_profile_page',
        JSON.stringify(canSwitchProfilePage)
      )
      state.canSwitchProfilePage = canSwitchProfilePage
    }
  }
}
