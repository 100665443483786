const messages = JSON.parse(localStorage.getItem('messages'))
const initialState = {
  read: messages ? messages.read : true,
  data: messages ? messages.data : []
}

export const message = {
  namespaced: true,
  state: initialState,
  actions: {
    setReadStatus ({ commit }, read) {
      commit('setReadStatus', read)
    }
  },
  mutations: {
    setReadStatus (state, read) {
      var messages = JSON.parse(localStorage.getItem('messages'))
      if (!messages) {
        localStorage.setItem(
          'messages',
          JSON.stringify({
            read: true,
            data: []
          })
        )
        state.read = true
        state.data = []
      }
      messages = JSON.parse(localStorage.getItem('messages'))
      messages.read = read
      state.read = read
      localStorage.setItem('messages', JSON.stringify(messages))
    }
  }
}
