import api from './api'

class UserService {
  info (options = {}, cancelToken = null) {
    return api.get('v1/friend/profile', {
      params: options,
      cancelToken: cancelToken
    })
  }

  infoMoreStats (options = {}, cancelToken = null) {
    return api.get('v1/friend/profile/summary/more-stats', {
      params: options,
      cancelToken: cancelToken
    })
  }

  account (options = {}, cancelToken = null) {
    return api.get('v1/friend/account', {
      params: options,
      cancelToken: cancelToken
    })
  }

  summary (options = {}, cancelToken = null) {
    return api.get('v1/friend/summary', {
      params: options,
      cancelToken: cancelToken
    })
  }

  summaryPersonnal (options = {}, cancelToken = null) {
    return api.get('v1/friend/summary/personnal', {
      params: options,
      cancelToken: cancelToken
    })
  }

  summaryInterest (options = {}, cancelToken = null) {
    return api.get('v1/friend/summary/interest', {
      params: options,
      cancelToken: cancelToken
    })
  }

  summaryJob (options = {}, cancelToken = null) {
    return api.get('v1/friend/summary/job', {
      params: options,
      cancelToken: cancelToken
    })
  }

  badges (options = {}, cancelToken = null) {
    return api.get('v1/friend/badges', {
      params: options,
      cancelToken: cancelToken
    })
  }

  social (options = {}, cancelToken = null) {
    return api.get('v1/friend/social', {
      params: options,
      cancelToken: cancelToken
    })
  }

  socialUpdate (options = {}, cancelToken = null) {
    return api.post('v1/friend/social-update', options, {
      cancelToken: cancelToken
    })
  }

  switchToTwitch (options = {}, cancelToken = null) {
    return api.get('v1/friend/switch/to/twitch', {
      params: options,
      cancelToken: cancelToken
    })
  }

  saveTwitchFAQS (options = {}, cancelToken = null) {
    return api.post('v1/friend/twitch/save/faqs', options, {
      cancelToken: cancelToken
    })
  }

  saveTwitchShedule (options = {}, cancelToken = null) {
    return api.post('v1/friend/twitch/save/shedule', options, {
      cancelToken: cancelToken
    })
  }

  saveTwitch (options = {}, cancelToken = null) {
    return api.get('v1/friend/hub-profile-social/oauth/twitch', {
      params: options,
      cancelToken: cancelToken
    })
  }

  updateInfos (options = {}, params = {}, cancelToken = null) {
    return api.post('v1/friend/update-infos', options, {
      params: params,
      cancelToken: cancelToken
    })
  }

  updateSummary (options = {}, params = {}, cancelToken = null) {
    return api.post('v1/friend/update-summary', options, {
      params: params,
      cancelToken: cancelToken
    })
  }

  updateSummaryPersonnal (options = {}, params = {}, cancelToken = null) {
    return api.post('v1/friend/update-summary/personnal', options, {
      params: params,
      cancelToken: cancelToken
    })
  }

  updateSummaryInterest (options = {}, params = {}, cancelToken = null) {
    return api.post('v1/friend/update-summary/interest', options, {
      params: params,
      cancelToken: cancelToken
    })
  }

  updateSummaryJob (options = {}, params = {}, cancelToken = null) {
    return api.post('v1/friend/update-summary/job', options, {
      params: params,
      cancelToken: cancelToken
    })
  }

  switchPrivate (options = {}, cancelToken = null) {
    return api.post('v1/friend/switch/to/private', {
      params: options,
      cancelToken: cancelToken
    })
  }

  askVerification (options = {}, cancelToken = null) {
    return api.post('v1/friend/ask-verification', {
      params: options,
      cancelToken: cancelToken
    })
  }

  changePassword (options = {}, cancelToken = null) {
    return api.post('v1/friend/change-password', options, {
      cancelToken: cancelToken
    })
  }

  updateAvatar (
    options = {},
    params = {},
    cancelToken = null,
    onUploadProgress = () => {}
  ) {
    return api.post('v1/friend/update-avatar', options, {
      params: params,
      cancelToken: cancelToken,
      onUploadProgress
    })
  }

  updateCover (
    options = {},
    params = {},
    cancelToken = null,
    onUploadProgress = () => {}
  ) {
    return api.post('v1/friend/update-cover', options, {
      params: params,
      cancelToken: cancelToken,
      onUploadProgress
    })
  }

  getAllNonAbonnes (cancelToken = null) {
    return api.get('v1/friend/notabonnement', { cancelToken: cancelToken })
  }

  getAll (options = {}, cancelToken = null) {
    return api.get('v1/friend/all', {
      params: options,
      cancelToken: cancelToken
    })
  }

  getAllMinimalist (options = {}, cancelToken = null) {
    return api.get('v1/friend/all/minimalist', {
      params: options,
      cancelToken: cancelToken
    })
  }

  getAllMembers (options = {}, cancelToken = null) {
    return api.post('v1/friend/members', options, { cancelToken: cancelToken })
  }

  sabonnerNext (data, cancelToken = null) {
    return api.post('v1/abonnements/sabonner/next', data, {
      cancelToken: cancelToken
    })
  }

  getAllRequest (options, cancelToken = null) {
    return api.get('v1/friend/request/all', {
      params: options,
      cancelToken: cancelToken
    })
  }

  friendDecline (data = {}, cancelToken = null) {
    return api.post('v1/friend/decline', data, { cancelToken: cancelToken })
  }

  friendAccept (data = {}, cancelToken = null) {
    return api.post('v1/friend/accept', data, { cancelToken: cancelToken })
  }

  friendNew (data = {}, cancelToken = null) {
    return api.post('v1/friend/new', data, { cancelToken: cancelToken })
  }

  friendRemove (data = {}, cancelToken = null) {
    return api.post('v1/friend/remove', data, { cancelToken: cancelToken })
  }

  getAllNotFriend (options = {}, cancelToken = null) {
    return api.get('v1/friend/notfriend', {
      params: options,
      cancelToken: cancelToken
    })
  }

  search (options = {}, cancelToken = null) {
    return api.get('v1/search/', { params: options, cancelToken: cancelToken })
  }

  popularGroups (options = {}, cancelToken = null) {
    return api.get('v1/search/popular-groups', {
      params: options,
      cancelToken: cancelToken
    })
  }

  searchMention (options = {}, cancelToken = null) {
    return api.get('v1/search/mention', {
      params: options,
      cancelToken: cancelToken
    })
  }

  blockIp (data = {}, cancelToken = null) {
    return api.post('v1/friend/block/ip', data, { cancelToken: cancelToken })
  }

  generateAffiliateCode (data = {}, cancelToken = null) {
    return api.post('v1/friend/generate-affiliate-code', data, {
      cancelToken: cancelToken
    })
  }
}

export default new UserService()
