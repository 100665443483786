import UserService from '../services/user.service'

const userLocal = JSON.parse(localStorage.getItem('user_profile'))
const menus = JSON.parse(localStorage.getItem('user_menus')) || {}
const wallet = JSON.parse(localStorage.getItem('user_wallet')) || {
  balance: 0,
  status: 'active',
  type: 'default'
}
const allInventory = JSON.parse(localStorage.getItem('unlockedFrame')) || 0
const initialState = { user: userLocal || null, menus: menus || {}, wallet, allInventory: allInventory || 0 }

export const user = {
  namespaced: true,
  state: initialState,
  actions: {
    setMenus ({ commit }, menus) {
      commit('menus', menus)
      return Promise.resolve(menus)
    },
    setUser ({ commit }, user) {
      commit('user', user)
      return Promise.resolve(user)
    },
    setWallet ({ commit }, wallet) {
      commit('wallet', wallet)
      return Promise.resolve(wallet)
    },
    setAllInventory ({ commit }, allInventory) {
      commit('allInventory', allInventory)
      return Promise.resolve(allInventory)
    },
    getUserInfos: ({ commit }, username) => {
      return UserService.info({ username: username }).then(
        (response) => {
          if (response.data.status === 200) {
            commit('user', response.data.user)
          }
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    user: function (state, user) {
      localStorage.setItem('user_profile', JSON.stringify(user))
      state.user = user
    },
    menus: function (state, menus) {
      localStorage.setItem('user_menus', JSON.stringify(menus))
      state.menus = menus
    },
    wallet: function (state, wallet) {
      localStorage.setItem('user_wallet', JSON.stringify(wallet))
      state.wallet = wallet
    },
    allInventory: function (state, allInventory) {
      localStorage.setItem('allInventory', allInventory)
      state.allInventory = allInventory
    }
  }
}
