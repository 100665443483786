class TokenService {
  getLocalRefreshToken () {
    const credential = JSON.parse(localStorage.getItem('credential'))
    return credential?.refreshToken
  }

  getLocalAccessToken () {
    const credential = JSON.parse(localStorage.getItem('credential'))
    return credential?.accessToken
  }

  updateLocalAccessToken (token) {
    const credential = JSON.parse(localStorage.getItem('credential'))
    credential.accessToken = token
    localStorage.setItem('credential', JSON.stringify(credential))
  }

  getCredential () {
    return JSON.parse(localStorage.getItem('credential'))
  }

  setCredential (credential) {
    localStorage.setItem(
      'credential',
      JSON.stringify({
        accessToken: credential.token,
        refreshToken: credential.refresh_token
      })
    )
  }

  removeCredential () {
    localStorage.removeItem('credential')
  }
}

export default new TokenService()
