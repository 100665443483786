import api from './api'

class GroupService {
  getAllSuggestions (options = {}, cancelToken = null) {
    return api.get('v1/page/suggestions', {
      params: options,
      cancelToken: cancelToken
    })
  }

  joinNext (options, cancelToken = null) {
    return api.post('v1/page/join/next', options, { cancelToken: cancelToken })
  }

  getAllOthers (options = {}, cancelToken = null) {
    return api.get('v1/page/others', {
      params: options,
      cancelToken: cancelToken
    })
  }

  getAll (options = {}, cancelToken = null) {
    return api.get('v1/page/list', {
      params: options,
      cancelToken: cancelToken
    })
  }

  getAllInvitations (options = {}, cancelToken = null) {
    return api.get('v1/page/invitations', {
      params: options,
      cancelToken: cancelToken
    })
  }

  join (options = {}, cancelToken = null) {
    return api.get('v1/page/join', {
      params: options,
      cancelToken: cancelToken
    })
  }

  leave (options = {}, cancelToken = null) {
    return api.get('v1/page/leave', {
      params: options,
      cancelToken: cancelToken
    })
  }

  profile (options = {}, cancelToken = null) {
    return api.get('v1/page/profile', {
      params: options,
      cancelToken: cancelToken
    })
  }

  addVisit (options = {}, cancelToken = null) {
    return api.get('v1/page/hasVisit', {
      params: options,
      cancelToken: cancelToken
    })
  }

  info (options = {}, cancelToken = null) {
    return api.get('v1/page/info', {
      params: options,
      cancelToken: cancelToken
    })
  }

  members (options = {}, cancelToken = null) {
    return api.get('v1/page/members', {
      params: options,
      cancelToken: cancelToken
    })
  }

  infoStats (options = {}, cancelToken = null) {
    return api.get('v1/page/info/stats', {
      params: options,
      cancelToken: cancelToken
    })
  }

  invite (options = {}, cancelToken = null) {
    return api.get('v1/page/invite', {
      params: options,
      cancelToken: cancelToken
    })
  }

  membersSuggestions (options = {}, cancelToken = null) {
    return api.get('v1/page/suggest_member', {
      params: options,
      cancelToken: cancelToken
    })
  }

  adminMembers (options = {}, cancelToken = null) {
    return api.get('v1/page/admin_member', {
      params: options,
      cancelToken: cancelToken
    })
  }

  moderatorMembers (options = {}, cancelToken = null) {
    return api.get('v1/page/moderator_member', {
      params: options,
      cancelToken: cancelToken
    })
  }

  adminAllForm (options = {}, cancelToken = null) {
    return api.get('v1/page/admin/all', {
      params: options,
      cancelToken: cancelToken
    })
  }

  moderatorAllForm (options = {}, cancelToken = null) {
    return api.get('v1/page/moderator/all', {
      params: options,
      cancelToken: cancelToken
    })
  }

  searchNotAdmin (options = {}, cancelToken = null) {
    return api.get('v1/search/page/not-admin', {
      params: options,
      cancelToken: cancelToken
    })
  }

  addAdmin (options = {}, cancelToken = null) {
    return api.post('v1/page/add/admins', options, { cancelToken: cancelToken })
  }

  addModerator (options = {}, cancelToken = null) {
    return api.post('v1/page/add/moderators', options, {
      cancelToken: cancelToken
    })
  }

  create (options = {}, cancelToken = null) {
    return api.post('v1/page/new', options, { cancelToken: cancelToken })
  }

  edit (options = {}, params = {}, cancelToken = null) {
    return api.post('v1/page/edit', options, {
      params: params,
      cancelToken: cancelToken
    })
  }

  delete (params = {}, cancelToken = null) {
    return api.delete(
      'v1/page/delete',
      { data: params },
      { cancelToken: cancelToken }
    )
  }

  getMyPages (options = {}, cancelToken = null) {
    return api.get('v1/page/admin_pages', {
      params: options,
      cancelToken: cancelToken
    })
  }

  switchPages (options = {}, cancelToken = null) {
    return api.get('v1/page/switch/pages', {
      params: options,
      cancelToken: cancelToken
    })
  }

  canSwitchPages (options = {}, cancelToken = null) {
    return api.get('v1/page/can/switch/pages', {
      params: options,
      cancelToken: cancelToken
    })
  }
}

export default new GroupService()
