import authService from '../services/auth.service'
import UserService from '../services/user.service'

const user = JSON.parse(localStorage.getItem('user'))
const credential = JSON.parse(localStorage.getItem('credential'))
const menus = JSON.parse(localStorage.getItem('menus')) || {}
const enabledRegistration =
  JSON.parse(localStorage.getItem('enabled_registration')) || false
const initialState = {
  status: { loggedIn: credential !== null },
  credential,
  user: credential !== null ? user : null,
  menus: credential !== null ? menus : null,
  enabledRegistration
}

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login ({ commit }, credential) {
      return authService.login(credential).then(
        (credential) => {
          commit('loginSuccess', credential)
          return Promise.resolve(credential)
        },
        (error) => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    logout ({ commit }) {
      authService.logout()
      commit('logout')
    },
    checkRegistration ({ commit }) {
      return authService.checkRegistration().then(
        (response) => {
          commit('enabledRegistration', response.enabled_registration)
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    register ({ commit }, user) {
      return authService.register(user).then(
        (response) => {
          if (response.token) {
            commit('loginSuccess', response)
          }

          return Promise.resolve(response)
        },
        (error) => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    resetPassword ({ commit }, data) {
      return authService.resetPassword(data).then(
        (response) => {
          if (response.data.token) {
            commit('loginSuccess', response.data)
          }
          return Promise.resolve(response)
        },
        (error) => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    refreshToken ({ commit }, refreshToken) {
      commit('refreshToken', refreshToken)
    },
    loginSuccess ({ commit }, credential) {
      commit('loginSuccess', credential)
      return Promise.resolve(credential)
    },
    setMenus ({ commit }, menus) {
      commit('menus', menus)
      return Promise.resolve(menus)
    },
    setEnabledRegistration ({ commit }, enabledRegistration) {
      commit('enabledRegistration', enabledRegistration)
      return Promise.resolve(enabledRegistration)
    },
    getUserInfos: ({ commit }) => {
      return UserService.info().then(
        (response) => {
          if (response.data.status === 200) {
            commit('user', response.data.user)
          }
          return Promise.resolve(response.data)
        },
        (error) => {
          commit('registerFailure')
          return Promise.reject(error)
        }
      )
    },
    setUser ({ commit }, user) {
      commit('user', user)
      return Promise.resolve(user)
    }
  },
  mutations: {
    loginSuccess (state, credential) {
      state.status.loggedIn = true
      const credentials = {
        accessToken: credential.token,
        refreshToken: credential.refresh_token
      }
      state.credential = credentials
    },
    loginFailure (state) {
      state.status.loggedIn = false
      state.credential = null
      state.user = null
      localStorage.removeItem('user')
    },
    user: function (state, user) {
      localStorage.setItem('user', JSON.stringify(user))
      state.user = user
    },
    menus: function (state, menus) {
      localStorage.setItem('menus', JSON.stringify(menus))
      state.menus = menus
    },
    enabledRegistration: function (state, enabledRegistration) {
      localStorage.setItem(
        'enabled_registration',
        JSON.stringify(enabledRegistration)
      )
      state.enabledRegistration = enabledRegistration
      state.status.loggedIn = false
    },
    logout (state) {
      state.status.loggedIn = false
      state.credential = null
      state.user = null
      state.menus = {}
      localStorage.removeItem('user')
      localStorage.removeItem('menus')
      authService.logout()
      return Promise.resolve()
    },
    registerSuccess (state) {
      state.status.loggedIn = false
    },
    registerFailure (state) {
      state.status.loggedIn = false
    },
    refreshToken (state, refreshToken) {
      state.status.loggedIn = true
      state.credential = { ...state.credential, refreshToken: refreshToken }
    }
  }
}
