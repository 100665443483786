/* eslint-disable */
import axiosInstance from './api'
import TokenService from './token.service'
import AuthService from './auth.service'
import router from '../router'

const setup = (store) => {
  axiosInstance.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (
        originalConfig &&
        originalConfig.url !== '/login_check' &&
        originalConfig.url !== '/token/refresh' &&
        err.response
      ) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            const rs = await axiosInstance.post('/token/refresh', {
              refresh_token: TokenService.getLocalRefreshToken()
            })

            const credentials = await AuthService.getNewRefreshToken()

            store.dispatch('auth/refreshToken', credentials.refresh_token)
            TokenService.updateLocalAccessToken(credentials.token)

            return axiosInstance(originalConfig)
          } catch (_error) {
            AuthService.logout()
            router.push('/')
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    }
  )
}

export default setup
